@import "../../assets/styles/vars";

div.Organizations {
  .rs-picker-disabled {
    opacity: 0.65;
  }

  button {
    margin: 0;
  }
}
