@import "../../assets/styles/vars";
@import "../../assets/styles/shared-styles";

.App {
  height: 100%;
  overflow: hidden;

  &-Header {
    height: $headerHeight;
    max-height: $headerHeight;
    z-index: 6;
  }

  &-Interface {
    height: calc(100% - #{$headerHeight});
    z-index: 1;
  }

  &-SidebarLayout {
    height: calc(100% - #{$headerHeight});
    display: grid;
    grid-template-columns: 410px auto;
    grid-template-rows: auto;
    flex-wrap: wrap;

    & .Sidebar {
      min-width: 320px;
    }

    & .Map {
    }

    @media screen and (max-width: 959px) {
      & {
        grid-template-columns: 1fr;
        grid-template-rows: 50% auto;

        & .Sidebar {
          grid-row: 2/4;
          position: sticky;
          left: 0;
          top: $headerHeight;
        }
      }
    }
  }

  &-login {
    max-width: 40rem;
    margin: auto;

    &-header {
      font-size: 1.5rem;
      color: $BLUE;
      text-align: center;
    }

    &-body {
      margin: 1rem 0;
      text-align: center;
    }

    &-button {
      margin: 1rem auto;
    }
  }
}
