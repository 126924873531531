@import "../../../assets/styles/vars";

.Avatar {
  &-user {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &-icon {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 0 1em;
      color: $WHITE;
      background-color: $GREY_300;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.8rem;
      text-align: center;
      line-height: 40px;
    }
  }
}
