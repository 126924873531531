//$DARK_BLUE: #282c34;
//$DARK_GREY: #505050;
//$WHITE: #fff;
//
//$BLUE: #5097ff;
//$ORANGE: #ff6000;
//$GREY_100: #f7f8fa;
//
$GREY_100: #f8f8fa;
$GREY_200: #e7e7e7;
$GREY_300: #bdc3c7;
$GREY_400: #95a5a6;
$GREY_500: #7f8c8d;
$GREY_600: #34495e;
$GREY_700: #2c3e50;

//
//$GREEN_100: #2ecc71;
//$GREEN_200: #27ae60;
//
//$TURQUOISE_100: #1abc9c;
//$TURQUOISE_200: #16a085;
//
//$BLUE_100: #3498db;
//$BLUE_200: #2980b9;
//
//$MAGENTA_100: #9b59b6;
//$MAGENTA_200: #8e44ad;
//
$RED: #ff0000;
$RED_100: #e74c3c;
//$RED_200: #c0392b;
////
//$ORANGE_100: #ff7029;
////$ORANGE_200: #d35400;
//
//$YELLOW_100: #f1c40f;
//$YELLOW_200: #f39c12;
//

$WHITE: #fff;
$BLACK: #222;
$GREY: #646464;
$BLUE: #0076ff;
$ORANGE: #ff6000;
$ORANGE_100: #f87720;
$PRIMARY: $ORANGE_100;
$GREEN: #23c360;

$GREY_100: #f7f8fa;
$GREY_200: #e6e6e6;
$GREY_300: #c8c8c8;
$GREY_350: #bebebe;
$GREY_400: #a2a2a2;
$GREY_450: #878787;
$GREY_500: #707070;
$GREY_600: #4a4a4a;
$GREY_700: #1e1e1e;
$GREY_800: #191919;
$BLUE_100: #397cf1;
