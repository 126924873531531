@import "vars";
$headerHeight: 55px;

%sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;

  & .text {
    color: $BLUE;
    font-size: 1.3em;
  }

  & .actionButton {
    font-size: 0.9rem;
    font-weight: 500;
  }
}

%closeBtn {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  right: 1em;
  z-index: 1;
  padding: 0;
  transform: translateY(-50%);

  &:before,
  &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: "";
    width: 30px;
    height: 4px;
    background-color: $GREY;
    border-radius: 3px;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.rs-btn-default {
  color: $WHITE;
  background-color: $ORANGE;
}
.rs-btn-default:hover {
  color: $WHITE;
  background-color: $ORANGE_100;
}
.rs-btn-disabled:disabled {
  color: $WHITE;
}