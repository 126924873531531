@import "../../assets/styles/vars";
@import "../../assets/styles/shared-styles";

.Reporting {
  overflow-y: auto;
  height: 100%;

  &-summary {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto;
    flex-wrap: wrap;

    &-total {
      color: $BLUE;
      padding: 2rem;
      text-align: center;
      font-size: 2rem;

      h3 {
        font-size: 1rem;
        font-weight: 400;
      }
    }
  }

  &-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 20px;
    row-gap: 20px;

    &-graph {
      height: 300px;
    }
  }

  &-sidebar {
  }
  &-toolbar {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    justify-content: space-between;
    flex-wrap: wrap;
    border: solid;
    border-width: 1px 0 1px 0;
    border-color: $GREY_200;
    background-color: $GREY_100;

    h1 {
      margin: 0 1rem;
    }

    &-controls {
      display: flex;
      align-items: center;
      padding: 0.5rem;
    }

    &-control {
      margin: 0 0.5rem;
    }
  }

  .NTD {
    padding: 10px;
    .divider {
      height: 30px;
    }
    .section {
      font-weight: bold;
      color: #13639d;
    }
  }
}
.selected {
  background-color: $GREY_300;
}
