@import "../../assets/styles/vars";
@import "../../assets/styles/shared-styles";

.Organizations {
  height: 100%;
  display: grid;
  grid-template-rows: auto auto;
  align-content: flex-start;

  &-toolbar {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    flex-wrap: wrap;
    border: solid;
    border-width: 1px 0 1px 0;
    border-color: $GREY_200;
    background-color: $GREY_100;

    h1 {
      margin: 0 1rem;
    }

    &-controls {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1rem;
    }
  }

  .scroll-container {
    position: relative;
    height: 100%;
    overflow-y: auto;

    .content-container {
      max-width: 500px;
      margin: 20px auto;
    }
  }

  &-button {
    overflow: visible;
  }
}
