@import "../../../assets/styles/vars";

.DayPicker {
  .Label {
    margin: 0.5rem;
    font-size: 0.75rem;
    color: $GREY_700;
  }

  &-Frame {
    margin: 0.5rem 0;
    display: flex;
    width: 19rem;
    &.disabled {
      opacity: 0.5;
      .Day {
        cursor: not-allowed;
      }
    }
  }

  .Input {
    display: none;
  }

  .Day {
    margin: 0.125rem;
    position: relative;
    display: inline-block;
    height: 40px;
    width: 40px;
    line-height: 38px;
    font-size: 0.8rem;
    border-radius: 50%;
    border: solid;
    border-width: 1px;
    border-color: #e6e6e6;
    text-align: center;
    cursor: pointer;
  }
  .Input:checked + .Day {
    color: $WHITE;
    background-color: $BLUE;
    border-color: $BLUE;
  }
}
