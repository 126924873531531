@import "../../assets/styles/vars";

.Header {
  padding: 0 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  height: 4.75rem;
  background-color: $GREY_100;

  &-logo {
    height: 2em;
    margin: 0.5em auto;

    &-image {
      max-height: 100%;
    }
  }
}

.Field {
  max-width: 400px;
  margin: 2rem auto;
  text-align: center;

  &-Heading {
    margin: 2.25rem 0;
    color: $BLUE;
    font-family: Oxygen;
    font-size: 2.25rem;
  }

  &-Summary {
    max-width: 250px;
    margin: 1rem auto;
    font-size: 0.9rem;
    color: $GREY_700;
  }
}

.Reload {
  position: relative;
  display: block;
  width: 100%;
  margin: 2rem 0;
  padding: 0.75rem 1.5rem;
  background-color: $ORANGE;
  border: none;
  border-radius: 0.25rem;
  line-height: 1.5rem;
  font-size: 1.125rem;
  font-family: Oxygen;
  font-weight: 900;
  color: $WHITE;
}
