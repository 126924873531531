@import "../../assets/styles/vars";
@import "../../assets/styles/shared-styles";

.Pools {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: stretch;

  &-sidebar {
    overflow: auto;
    display: grid;
    width: 600px;
    grid-template-columns: auto;
    grid-template-rows: 54px auto;
    align-content: stretch;
  }

  .PoolItemContainer {
    display: grid;
    row-gap: 0px;
    align-items: start;
  }

  .PoolItem {
    margin: 6px;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 8px;
    display: grid;
    grid-template-columns: auto max-content;
    row-gap: 10px;

    &.selected {
      border: 1px solid $ORANGE;
      background-color: #efefef;
    }

    .Props {
      justify-self: stretch;
    }
  }

  &-scroll {
    overflow: auto;
    justify-self: stretch;
  }

  &-map {
    width: 100%;
    height: 100%;
  }
  &-toolbar {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    flex-wrap: wrap;
    border: solid;
    border-width: 1px 0 1px 0;
    border-color: $GREY_200;
    background-color: $GREY_100;

    h1 {
      margin: 0 1rem;
    }

    &-controls {
      display: flex;
      justify-content: flex-start;
      padding: 0.5rem;

      @media screen and (max-width: 959px) {
        flex-direction: column;
        align-items: baseline;
      }
    }

    &-search {
      padding: 0 0.5rem;
      width: 100%;
    }

    &-dropdown {
      padding: 0 0.5rem;
      width: 100%;

      @media screen and (max-width: 959px) {
        margin-bottom: 0.25rem;
      }

      &-label {
        color: $ORANGE;
        width: 10%;
        margin-right: 5px;
      }

      &-picker {
        width: 100%;
      }
    }

    &-add {
      margin-left: auto;
      padding: 0.5rem;
    }

    &-input {
      max-width: 33vw;
      width: auto;
      right: 0;
    }
  }
}
button {
  margin: 0 0.5rem;
}
