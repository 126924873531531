.custom-field-editor {
  margin-top: 20px;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 15px;

  .rs-form-horizontal {
    .rs-form-group {
      .rs-control-label {
        width: 80px;
      }
    }

    .rs-form-group:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .custom-field-summary {
    display: grid;
    grid-template-columns: auto 50px;
    justify-content: space-between;
  }
}
