@import "../../assets/styles/vars";

.label {
  margin: 5px;
  min-width: 20%;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row {
  margin: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.input {
  width: 70%;
}

.errors {
  display: flex;
}

.error {
  color: #bf1650;
}

.error::before {
  display: inline;
  content: "⚠ ";
}

.field {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
