@import "../../assets/styles/vars";
@import "../../assets/styles/shared-styles";

.Users {
  height: 100%;
  display: grid;
  grid-template-rows: auto auto;
  align-content: flex-start;

  &-toolbar {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    justify-content: space-between;
    flex-wrap: wrap;
    border: solid;
    border-width: 1px 0 1px 0;
    border-color: $GREY_200;
    background-color: $GREY_100;

    h1 {
      margin: 0 1rem;
    }

    &-controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0.5rem;
    }
  }

  .scroll-container {
    position: relative;
    height: 100%;
    overflow-y: auto;
  }
}
