@import "../../assets/styles/vars";
@import "../../assets/styles/shared-styles";

.Map {
}

.Controls {
  display: block;
  position: absolute;
  top: 4rem;
  right: 1rem;

  &-Button {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    line-height: 1.5rem;
    padding: 0;
    text-align: center;
    background-color: $GREY_100;
    border-radius: 2px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  }

  &-Icon {
    height: 0.6rem;
    width: 0.6rem;
  }
}

.Marker-test {
  width: 100%;
  max-width: 50px;
  max-height: 50px;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

@media screen and (max-width: 959px) {
  .desktop {
    display: none;
  }
}
