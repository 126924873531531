@import "../../../../assets/styles/vars";

.MapMarkerPin {
  position: relative;

  &-data {
    position: absolute;
    top: 0;
    left: 50%;
    min-width: 10rem;
    z-index: 1;
    transform: translate(-50%, -80%);
    background-color: $WHITE;
    padding: 0.25rem;
    border-radius: 3px;
    box-shadow: 0 0 5px 0 $GREY_300;
    text-align: center;

    &-number {
      width: 1.5rem;
      height: 1.5rem;
      background-color: $GREY_400;
      color: $WHITE;
      position: absolute;
      left: 0.25em;
      top: 0.25em;
      z-index: 3;
      font-size: 0.75em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }

  &-icon {
    width: 1.75rem;
    position: relative;
    z-index: 2;
  }
}
