@import "../../assets/styles/vars";
@import "../../assets/styles/shared-styles";

.Waitlist {
  height: 100%;
  display: grid;
  grid-template-columns: 60% auto;
  grid-template-rows: auto;
  flex-wrap: wrap;

  &-scroll {
    overflow: auto;
  }

  &-sidebar {
    overflow: auto;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    align-content: start;
  }
  &-toolbar {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    flex-wrap: wrap;
    border: solid;
    border-width: 1px 0 1px 0;
    border-color: $GREY_200;
    background-color: $GREY_100;
    align-self: start;

    h1 {
      margin: 0 1rem;
    }

    &-controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0.5rem;
    }
  }
  &-map {
    width: 100%;
    height: 100%;
  }

  .ColorBlock-Small {
    width: 38px;
    height: 38px;
  }
}
