@import "../../assets/styles/vars";

.PoolsEditor {
  margin: 0px 10px;

  &-Label {
    margin: 0.5rem;
    font-size: 0.75rem;
    color: $GREY_700;
  }

  &-Split {
    display: inline-block;
    width: 50%;
  }
  &-Date {
    display: block;
    margin: 0.5rem;
    padding: 0.5rem 0.75rem;
    border: solid;
    border-width: 1px;
    border-color: $GREY_700;
    border-radius: 0.25rem;
  }
  &-Schedule {
    flex-direction: "row";
    width: 300px;
    margin: 0.5rem;
    &-Title {
      font-size: 0.75rem;
      color: $GREY_500;
      text-align: center;
    }
    &-StopType {
      button {
        margin: 0;
      }
      &-Fixed {
        cursor: not-allowed;
      }
    }
  }
  &-Empty {
    text-align: center;
    width: 300px;
    height: 3rem;
    line-height: 3rem;
    color: $GREY_500;
    background-color: $GREY_100;
  }
  &-Rules {
    &-List {
      flex-direction: "row";
      width: 300px;
      margin: 0.5rem;
    }
    &-Add {
      width: 300px;
    }
    &-Error {
      margin: 0.5rem;
      width: 300px;
    }
  }

  div.ServicePanel {
    border: 1px solid #e5e5ea;
    border-radius: 6px;

    .header {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      align-items: center;
      display: pointer;
      position: relative;
      height: 60px;
      padding: 10px;
      padding-right: 40px;
      padding-left: 20px;

      .title::before {
        position: absolute;
        font-family: "rsuite-icon-font";
        content: "\ea08";
        top: 20px;
        right: 20px;
      }
    }

    &.expanded {
      .header > .title::before {
        transform: rotate(180deg);
      }
    }
  }

  .Services-Container {
    margin-top: 30px;
    border: none;
    padding: 10px;
    h4 {
      font-size: 1.2rem;
    }
    .section {
      margin-top: 20px;
    }
    .upcoming-header {
      display: grid;
      grid-template-columns: auto 100px;
      button {
        margin: 0;
      }
    }
  }

  .duplicate-container {
    float: left;
    width: 300px;
    background-color: #efefef;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 10px;

    .action {
      display: block;
      margin: 10px auto;
    }
  }

  .rs-form-horizontal {
    .rs-form-group {
      .rs-form-control-wrapper {
        .rs-picker-toggle-wrapper {
          width: 300px;
        }
      }
      > rs-picker-toggle-wrapper {
        width: 100vw;
      }
      .rs-picker-disabled {
        opacity: 0.6;
      }
    }
  }

  // fix rsuite for mobile
  @media screen and (max-width: 959px) {
    margin: 10px 5px;
    max-width: 100%;
    &-Schedule {
      width: auto;
    }
    &-Rules {
      &-List {
        width: auto;
      }
      &-Add {
        width: 100%;
      }
      &-Error {
        width: 96%;
      }
    }

    .rs-form-horizontal {
      .rs-form-group {
        .rs-control-label {
          float: none;
          display: inline;
        }
        .rs-form-control-wrapper {
          float: none;
          .rs-picker-toggle-wrapper {
            width: 100vw;
          }
        }
        .rs-input {
          width: 100%;
        }
        .rs-btn-toolbar {
          display: inline;
        }
        > rs-picker-toggle-wrapper {
          width: 100vw;
        }
      }
    }
  }
}
.form-error {
  align: center;
  display: inline-block;
  color: $RED;
}
